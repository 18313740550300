<template>
    <div class="appointment-List">
        <van-tabs v-model="activeName" class="list-tabs" @change="getAppointmentData"  :title-active-color="'#303133'" :title-inactive-color="'#303133'">
            <van-tab title="未使用" name="1"></van-tab>
            <van-tab title="已使用" name="2"></van-tab>
            <van-tab title="已取消" name="3"></van-tab>
            <van-tab title="已过期" name="4"></van-tab>
        </van-tabs>
        <div class="list-box" v-show="appointmentList.length!=0">
            <div class="item-box" :class="'item'+activeName" v-for="(item,index) in appointmentList" :key="index" @click="goDetails(item)">
                <div class="box-left">
                    <p class="title-name">{{item.reserveProjectName}}</p>
                    <p class="time">
                        预约时间：{{item.reserveDate}}（{{item.reserveTimeRangeStart}}-{{item.reserveTimeRangeEnd}}）
                    </p>
                </div>
                <div class="right-status">
                    <span class="txt">
                        {{activeName==1?'未使用':activeName==2?'已使用':activeName==3?'已取消':'已过期'}}
                    </span>
                </div>
            </div>
        </div>
         <div class="nodata" v-if="appointmentList.length==0">
            <img src="@/assets/img/data-null.png" >
        </div>
    </div>
</template>

<script>
import { ReserveList } from "@/api/appointment";
import { getLocalMinutesTime } from "@/utils/date";
export default {
    data(){
        return{
            appointmentList:[],
            getLocalMinutesTime,
            activeName:"1"
        }
    },
    created(){
        document.title = this.$t("appointment.appointmentList");
        this.getAppointmentData();
    },
    methods:{
        async getAppointmentData(){
            let datadd = {};
            this.appointmentList = [];
            if(this.activeName == 1||this.activeName == 2){
                datadd.isCheckin = this.activeName==1?0:1;
                datadd.isCanceled = 0;
                datadd.isExpired = 0;
            }
            if(this.activeName == 3){
                datadd.isCanceled = 1;
                datadd.isExpired = 0;
                datadd.isCheckin = 0;
            }
            if(this.activeName == 4){
                datadd.isExpired = 1;
                datadd.isCheckin = 0;
                datadd.isCanceled = 0;
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await ReserveList(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.appointmentList = result.data.data;
            }else{
                this.appointmentList = [];
            }
        },
        goDetails(item){
            this.$router.push({
                path:"/appointment/details",
                query:{
                    reserveUserId:item.reserveUserId,
                    reserveId:item.reserveId
                }
            })
            // /reserve2/user-reserve/get
        },
    },
}
</script>

<style lang="scss" scoped>
.appointment-List{
    height: 100%;
    background: #F5F6FA;
    display: flex;
    flex-direction: column;
    .list-tabs{
        height: 2rem;
        .van-tabs__wrap{
            height: 100%;
            .van-tab--active{
                .van-tab__text{
                    color: #3E8EFA;
                }
            }
            .van-tabs__line{
                width: 1rem;
                height: 0.2rem;
                background: #3E8EFA;
                border-radius: 0.1rem;
            }
        }
    }
    .list-box{
        flex: 1;
        overflow: auto;
        margin-bottom: 0.6rem;
        .item-box{
            margin: 0.6rem 0.6rem 0 0.6rem;
            border-radius: 0.32rem;
            display: flex;
            .box-left{
                flex: 1;
                background: #FFFFFF;
                border-radius: 0.32rem;
                padding: 1rem 1.3rem 1rem 0.6rem;
                .title-name{
                    font-size: 0.64rem;
                    font-weight: 500;
                    color: #303133;
                    line-height: 0.9rem;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;
                }
                .time{
                    margin-top: 0.2rem;
                    font-size: 0.56rem;
                    font-weight: 400;
                    color: #909399;
                    line-height: 0.8rem;
                }
            }
            .right-status{
                width: 1.6rem;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                &::after{
                    width: 0.8rem;
                    height: 0.8rem;
                    position: absolute;
                    top: 50%;
                    content: ' ';
                    border-radius: 50%;
                    transform: translate(0,-50%);
                    left: -0.4rem;
                }
                .txt{
                    font-size: 0.48rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0.66rem;
                    width: 0.6rem;
                    opacity: 0.4rem;
                }
            }
        }
        .item1{
            background: #DCEAFB;
            .right-status::after{
                background: #DCEAFB;
            }
        }
        .item2{
            background: #F7DBDF;
            .right-status::after{
                background: #F7DBDF;
            }
        }
        .item4{
            background: #C0C0C3;
            .right-status::after{
                background: #C0C0C3;
            }
        }
        .item3{
            background: #EFD4AE;
            .right-status::after{
                background: #EFD4AE;
            }
        }
    }
    .nodata{
        flex: 1;
        width: 100%;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 8rem;
            height: auto;
        }
    }
}
</style>
